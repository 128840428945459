<template>
  <div></div>
</template>
<script>
export default {
  name: "jump",
  created() {
    let path = this.$route.query.path == "" ? "/" : this.$route.query.path;
    let url = this.$tools.getURL(
      `${this.$config.host}#${path}`,
      this.$route.query
    );
    let oauthUrl = this.$tools.getURL(this.$urls.oauth, {
      url: encodeURIComponent(url),
    });
    let link = this.$tools.getURL(this.$urls.openid, {
      link: encodeURIComponent(oauthUrl),
      id: this.$config.globalProject,
    });
    window.location.href = link;
  },
};
</script>